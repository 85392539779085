<script>
// import router from "../../index";
import axios from "axios";
export default {
  created() {
    var config_logout = {
      method: "post",
      url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/logout",
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
    };
    axios(config_logout)
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    localStorage.removeItem("user");
    localStorage.removeItem("session");
    localStorage.removeItem("session_username");
    localStorage.removeItem("session_name");
    localStorage.removeItem("session_path_photo");
    localStorage.removeItem("access_token");
    localStorage.removeItem("session_role");
    localStorage.removeItem("session_role_active_id");
    localStorage.removeItem("session_role_active_name");
    localStorage.removeItem("session_role_active_index");
    localStorage.removeItem("session_menu_access");
    localStorage.removeItem("reload_already");
    localStorage.removeItem("dateLogin");
    // router.push("/login");
    window.location.reload();
  },
};
</script>
